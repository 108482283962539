<template>
  <div class="field-property-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="属性名称">
              <el-input v-model="searchForm.name" placeholder="搜索属性称"></el-input>
            </el-form-item>
            <el-form-item label="字段">
              <el-select v-model="searchForm.fieldId" placeholder="选择字段">
                <el-option
                  v-for="field in fields"
                  :key="field.id"
                  :label="field.name"
                  :value="field.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchFieldProperties">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openDialog()">新增属性</el-button>
        </div>
      </div>
    </el-card>

    <el-card class="field-property-list">
      <el-table v-loading="loading" :data="fieldProperties" style="width: 100%">
        <el-table-column prop="key" label="属性键"></el-table-column>
        <el-table-column prop="value" label="属性值"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button link type="primary" @click="editProperty(scope.row)">编辑</el-button>
            <el-button link type="danger" @click="deleteProperty(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 属性编辑对话框 -->
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="propertyForm" label-width="100px" :rules="rules" ref="propertyFormRef">
        <el-form-item label="属性键" prop="key">
          <el-input v-model="propertyForm.key"></el-input>
        </el-form-item>
        <el-form-item label="属性值" prop="value">
          <el-input v-model="propertyForm.value"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input type="textarea" v-model="propertyForm.description"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitProperty">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'

export default {
  name: 'FieldPropertyManagement',
  setup() {
    const loading = ref(false)
    const fieldProperties = ref([])
    const fields = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增属性')
    const propertyFormRef = ref(null)

    const searchForm = reactive({
      name: '',
      fieldId: ''
    })

    const propertyForm = reactive({
      old_key: '',
      key: '',
      value: '',
      description: ''
    })

    const rules = {
      key: [{ required: true, message: '请输入属性键', trigger: 'blur' }],
      value: [{ required: true, message: '请输入属性值', trigger: 'blur' }],
    }

    const searchFieldProperties = async () => {
      loading.value = true
      try {
        const params = {
          key: searchForm.name,
          field_id: searchForm.fieldId,
          page: currentPage.value,
          page_size: pageSize.value
        }
        const res = await get('/auth/admin/field_properties', params)
        fieldProperties.value = res.properties
        total.value = res.total
      } catch (error) {
        ElMessage.error('获取属性列表失败')
      } finally {
        loading.value = false
      }
    }

    const resetSearch = () => {
      searchForm.name = ''
      searchForm.fieldId = ''
      searchFieldProperties()
    }

    const fetchFields = async () => {
      try {
        const res = await get('/auth/admin/fields', { include_disabled: true, page: 1, page_size: 1000 })
        fields.value = res.fields
      } catch (error) {
        ElMessage.error('获取字段列表失败')
      }
    }

    const openDialog = () => {
      dialogTitle.value = '新增属性'
      Object.assign(propertyForm, {
        old_key: '',
        key: '',
        value: '',
        description: ''
      })
      dialogVisible.value = true
    }

    const editProperty = (row) => {
      dialogTitle.value = '编辑属性'
      console.log('编辑的行数据:', row)
      Object.assign(propertyForm, {
        old_key: row.key,
        key: row.key,
        value: row.value,
        description: row.description || ''
      })
      console.log('propertyForm数据:', propertyForm)
      dialogVisible.value = true
    }

    const deleteProperty = (row) => {
      ElMessageBox.confirm('确定要删除这个属性吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/field_property/${encodeURIComponent(row.key)}`)
          ElMessage.success('删除成功')
          searchFieldProperties()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const submitProperty = async () => {
      try {
        await propertyFormRef.value.validate()
        console.log('提交时的表单数据:', propertyForm)
        
        const submitData = {
          key: propertyForm.key,
          value: propertyForm.value,
          description: propertyForm.description
        }

        if (propertyForm.old_key) {
          submitData.old_key = propertyForm.old_key
          await put(`/auth/admin/field_property/${encodeURIComponent(propertyForm.old_key)}`, submitData)
          ElMessage.success('更新成功')
        } else {
          await post('/auth/admin/field_property', submitData)
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchFieldProperties()
      } catch (error) {
        console.error('提交失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      currentPage.value = 1
      searchFieldProperties()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchFieldProperties()
    }

    onMounted(async () => {
      await fetchFields()
      await searchFieldProperties()
    })

    return {
      loading,
      fieldProperties,
      fields,
      searchForm,
      searchFieldProperties,
      resetSearch,
      dialogVisible,
      dialogTitle,
      propertyForm,
      propertyFormRef,
      rules,
      openDialog,
      editProperty,
      deleteProperty,
      submitProperty,
      currentPage,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange
    }
  }
}
</script>

<style scoped>
.field-property-management {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.field-property-list {
  flex-grow: 1;
  overflow-y: auto;
}
.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
</style> 