import axios from 'axios';
import { ElMessage, ElNotification } from 'element-plus';
import { nextTick } from 'vue';

let baseUrl = null;

function setGlobals(globalProperties) {
	baseUrl = globalProperties.$BaseUrl;
}

// 格式化时间
const formatTime = date => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${[year, month, day].map(formatNumber).join('-')} ${[hour, minute, second].map(formatNumber).join(':')}`;
};

// 获取时间戳
const getDateStamp = date => {
  let dateStr = formatTime(date);
  return new Date(dateStr).getTime();
};

// 数字格式化
const formatNumber = n => {
  n = n.toString();
  return n[1] ? n : `0${n}`;
};

// 验证对象是否为空
function isNull(data) {
  return data == null || data === 0 || data === "";
}

// 是否为整数
function isInt(data) {
  var i = parseFloat(data);
  return i % 1 === 0;
}

// 获取时间戳的开始和结束
function getStampStartEnd(stamp) {
  var date = new Date(parseInt(stamp) * 1000);
  return getDateStartEnd(date);
}

// 获取一天的开始和结束时间戳
function getDateStartEnd(date) {
  const tmp = date.setHours(0, 0, 0, 0);
  const start = parseInt(new Date(tmp).getTime());
  const end = parseInt(new Date(date.setHours(23, 59, 59, 999)).getTime());
  return [start, end];
}

// 通用请求（不需要token）
function commonRequest(method, url, data, headers = {}) {
  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }
  console.log(headers)
  return new Promise((resolve, reject) => {
    axios({
      baseURL: baseUrl, // 使用环境变量设置BaseUrl
      url: '/api' + url,
      method: method,
      data,
      headers: headers,
      referrerPolicy: 'no-referrer-when-downgrade',
    }).then(res => {
      const fileName = res.headers['Filename'];
      if (res.status === 200) {
        if (res.data) {
          fileName ? resolve(res.data, fileName) : resolve(res.data);
        }
      } else {
        reject(res.data.msg || res.data || res);
      }
    }).catch(err => {
      reject(err.response?.data?.msg || err.response?.data || err.message || '未知错误');
    });
  });
}

// 验证请求（需要token）
async function authRequest(context, method, url, data, headers = {}) {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (!userInfo || !userInfo.token) {
    showNotification("请先登录", "warning");
    if (context && context.$router) {
      console.log("正在跳转到登录页面...");
      await nextTick();
      await context.$router.push({ name: 'login' });
      console.log("已跳转到登录页面");
    } else {
      console.error("上下文中没有可用的路由实例");
    }
    return Promise.reject("未登录");
  }
  
  headers.Authorization = userInfo.token;
  
  try {
    return await commonRequest(method, url, data, headers);
  } catch (e) {
    let status = e.response?.status;
    let errorMessage = e.response?.data?.msg || e.response?.data || e.message || e || "未知错误";
    
    if (status === 401 || status === 403) {
      showNotification(status === 401 ? "登录已过期，请重新登录" : "没有权限访问", "error");
      localStorage.removeItem("userInfo");
      if (context && context.$router) {
        await nextTick();
        await context.$router.push({ name: 'login' });
      } else {
        console.error("上下文中没有可用的路由实例");
      }
    } else {
      showNotification(errorMessage, "error");
    }
    return Promise.reject(errorMessage);
  }
}

// 获取当前系统用户信息
async function curSysUserInfo() {
  return await commonRequest("get", "/sys/user");
}

// 获取当前用户信息
async function curUserInfo() {
  return await commonRequest("get", "/user");
}

// 文件上传
async function authUploadFile(that, url, contentType, data) {
  let token = localStorage.getItem("token");
  if (!token) {
    ElMessage.error("登录失效，请重新登录！");
    return;
  }
  return new Promise((resolve, reject) => {
    axios({
      baseURL: baseUrl, // 使用环境变量设置BaseUrl
      url: '/api' + url,
      method: "post",
      data,
      headers: {
        "Content-Type": contentType,
        "token": token
      },
    }).then(res => {
      if (res.status === 200 && res.data) {
        resolve(res.data);
      } else {
        reject(res);
      }
    }).catch(err => {
      reject(err);
    });
  }).catch(e => {
    if (e.status === 401) {
      ElMessage.error("登录失效，请重新登录！");
      localStorage.removeItem("token");
    }
    return e;
  });
}

// 延迟函数
function sleep(ms) {
  var unixtime_ms = new Date().getTime();
  while (new Date().getTime() < unixtime_ms + ms) {
	//等待
  }
}

// 动态加载 CSS
function loadCSS(url, isCache = false) {
  let element = document.createElement("link");
  element.setAttribute("rel", "stylesheet");
  element.setAttribute("type", "text/css");
  element.setAttribute("href", isCache ? url + "?t=" + new Date().getTime() : url);
  document.head.appendChild(element);
}

// 判断是否为移动设备
function _isMobile() {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}

// 新的提示函数
export function showNotification(message, type = 'error', title = '提示') {
  ElNotification({
    title: title,
    message: message,
    type: type,
    duration: 5000,  // 设置为0表示不会自动关闭
    position: 'top-right'
  });
}

export function getCurrentUserId() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
  return userInfo.user.base_info.id || ''
}

export default {
  commonRequest,
  authRequest,
  curUserInfo,
  curSysUserInfo,
  authUploadFile,
  sleep,
  loadCSS,
  _isMobile,
  formatTime,
  getDateStamp,
  getDateStartEnd,
  isNull,
  isInt,
  getStampStartEnd,
  setGlobals,
  showNotification,
  getCurrentUserId
};