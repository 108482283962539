// 环境配置
const ENV = {
  dev: {
    BASE_URL: 'http://127.0.0.1:8417/api',
    // 其他开发环境配置
    DEBUG: true,
    PORT: 3000 // 开发环境端口
  },
  release: {
    BASE_URL: 'https://tktkai.cn/api',
    // 其他生产环境配置
    DEBUG: false,
    PORT: 80 // 生产环境端口
  }
};

// 当前环境，可以根据需要修改
const CURRENT_ENV = 'release';

module.exports = {
  ...ENV[CURRENT_ENV],
  // 可以添加一些通用配置
  VERSION: '1.0.0',
  APP_NAME: 'miluai',
};
