<template>
  <div class="login-container">
    <div class="login-content">
      <div class="login-header">
        <h1>儿童测评系统管理后台</h1>
        <p>欢迎回来，请登录您的账户</p>
      </div>
      <el-form :model="loginForm" :rules="rules" ref="loginForm" class="login-form">
        <el-form-item prop="username">
          <el-input 
            v-model="loginForm.username" 
            prefix-icon="el-icon-user" 
            placeholder="请输入用户名"
            class="input-with-shadow">
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input 
            v-model="loginForm.password" 
            prefix-icon="el-icon-lock" 
            type="password" 
            placeholder="请输入密码"
            class="input-with-shadow">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="rememberMe">记住我</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="login-button" @click="submitForm('loginForm')">登录</el-button>
        </el-form-item>
      </el-form>
      <div class="login-footer">
        <a href="#">忘记密码？</a>
      </div>
    </div>
    <div class="login-image">
      <img src="/static/images/index.webp" alt="登录背景图" />
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request';

export default {
  name: 'LoginPage',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rememberMe: false,
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度至少为6个字符', trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {
    this.loadSavedCredentials();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    async login() {
      try {
        const res = await post('/login', this.loginForm);
        if (res.code === 200) {
          this.saveUserInfo(res.data);
          if (this.rememberMe) {
            this.saveCredentials();
          } else {
            this.clearSavedCredentials();
          }
          
          this.$router.push({ name: 'Home' });
        } else {
          this.$message.error(res.message || '登录失败');
        }
      } catch (err) {
        console.error('登录失败:', err);
      }
    },
    saveUserInfo(data) {
      localStorage.setItem('userInfo', JSON.stringify(data));
    },
    saveCredentials() {
      localStorage.setItem('savedUsername', this.loginForm.username);
      localStorage.setItem('savedPassword', this.loginForm.password);
    },
    loadSavedCredentials() {
      const savedUsername = localStorage.getItem('savedUsername');
      const savedPassword = localStorage.getItem('savedPassword');
      if (savedUsername && savedPassword) {
        this.loginForm.username = savedUsername;
        this.loginForm.password = savedPassword;
        this.rememberMe = true;
      }
    },
    clearSavedCredentials() {
      localStorage.removeItem('savedUsername');
      localStorage.removeItem('savedPassword');
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  height: 100vh;
  background-color: #f0f2f5;
}

.login-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.login-header h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.login-header p {
  color: #666;
}

.login-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login-button {
  width: 100%;
}

.login-footer {
  text-align: center;
  margin-top: 1rem;
}

.login-footer a {
  color: #1890ff;
  text-decoration: none;
}

.login-image {
  flex: 1;
  background-color: #1890ff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-with-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-image {
    display: none;
  }

  .login-content {
    padding: 2rem;
  }
}
</style>
