<template>
  <router-view></router-view>
</template>

<script>
import { fetchAndCacheConstants } from '@/utils/constants';

export default {
  name: 'app',
  async created() {
    await fetchAndCacheConstants(); // 获取并缓存常量和身份信息
    this.$globalProperties = {
      isMobile: this._isMobile()
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
