<template>
  <div class="system-settings">
    <el-card class="mb-4 top-bar-card">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="设置名称">
              <el-input v-model="searchForm.key" placeholder="设置名称"></el-input>
            </el-form-item>
            <el-form-item label="显示名称">
              <el-input v-model="searchForm.showName" placeholder="显示名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchSettings">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </div>
      </div>
    </el-card>

    <el-card class="settings-list-card">
      <el-table v-if="settings.length > 0" :data="settings" style="width: 100%">
        <el-table-column prop="key" label="设置名称"></el-table-column>
        <el-table-column prop="showName" label="显示名称"></el-table-column>
        <el-table-column prop="value" label="设置值"></el-table-column>
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button link type="primary" @click="editSetting(scope.row)">编辑</el-button>
            <el-button link type="danger" @click="deleteSetting(scope.row.key)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-empty v-else description="暂无数据"></el-empty>
    </el-card>

    <el-dialog
      title="编辑设置"
      v-model="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="currentSetting" label-width="120px">
        <el-form-item label="设置名称">
          <el-input v-model="currentSetting.key" disabled></el-input>
        </el-form-item>
        <el-form-item label="显示名称">
          <el-input v-model="currentSetting.showName"></el-input>
        </el-form-item>
        <el-form-item label="设置值">
          <el-input v-model="currentSetting.value"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitSetting">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { put, del } from '@/utils/request'

export default {
  name: 'SystemSettings',
  setup() {
    const searchForm = reactive({
      key: '',
      showName: ''
    })
    const settings = ref([])
    const dialogVisible = ref(false)
    const currentSetting = ref({})

    const searchSettings = async () => {
      try {
        // const res = await get('/auth/admin/setting', searchForm)
        // settings.value = res.data
      } catch (error) {
        console.error('获取设置列表失败:', error)
        ElMessage.error('获取设置列表失败')
      }
    }

    const resetSearch = () => {
      searchForm.key = ''
      searchForm.showName = ''
      searchSettings()
    }

    const editSetting = (row) => {
      currentSetting.value = { ...row }
      dialogVisible.value = true
    }

    const submitSetting = async () => {
      try {
        await put('/auth/admin/setting', currentSetting.value)
        ElMessage.success('修改设置成功')
        dialogVisible.value = false
        searchSettings()
      } catch (error) {
        console.error('修改设置失败:', error)
        ElMessage.error('修改设置失败')
      }
    }

    const deleteSetting = (key) => {
      ElMessageBox.confirm('确定要删除这个设置吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/setting/${key}`)
          ElMessage.success('删除成功')
          searchSettings()
        } catch (error) {
          console.error('删除设置失败:', error)
          ElMessage.error('删除设置失败')
        }
      }).catch(() => {})
    }

    // 初始加载设置列表
    searchSettings()

    return {
      searchForm,
      settings,
      dialogVisible,
      currentSetting,
      searchSettings,
      resetSearch,
      editSetting,
      submitSetting,
      deleteSetting
    }
  }
}
</script>

<style scoped>
.system-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top-bar-card {
    height: 120px;
    display: flex;
    justify-content: space-between;
    justify-items: center;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.settings-list-card {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}
</style>
