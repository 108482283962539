<template>
  <el-upload
    :action="`${apiBaseUrl}/auth/file/upload`"
    :data="uploadData"
    :on-success="handleUploadSuccess"
    :before-upload="beforeUpload"
    :file-list="fileList"
    :headers="uploadHeaders"
    :accept="accept"
    :multiple="multiple"
    :limit="limit"
    :on-exceed="handleExceed"
  >
    <el-button :icon="uploadIcon">{{ buttonText }}</el-button>
    <template #tip>
      <div class="el-upload__tip" v-if="tip">{{ tip }}</div>
    </template>
  </el-upload>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import env from '../../.env.js'
import { ElMessage } from 'element-plus'
import { getConstantByKey } from '@/utils/constants'

export default {
  name: 'FileUpload',
  props: {
    fileType: {
      type: Number,
      required: true
    },
    buttonText: {
      type: String,
      default: '点击上传'
    },
    uploadIcon: {
      type: String,
      default: 'el-icon-upload'
    },
    accept: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    },
    tip: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 100 // 默认最大100MB
    }
  },
  emits: ['upload-success'],
  setup(props, { emit }) {
    const apiBaseUrl = env.BASE_URL
    const fileTypes = ref([])
    const uploadHeaders = computed(() => {
      const userInfoString = localStorage.getItem('userInfo')
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null
      return userInfo && userInfo.token ? { Authorization: userInfo.token } : {}
    })

    onMounted(() => {
      fileTypes.value = getConstantByKey('fileTypes') || []
    })
    const fileList = ref([])

    const handleUploadSuccess = (response, file, fileList) => {
      emit('upload-success', response, file, fileList)
    }

    const uploadData = computed(() => ({
      fileType: props.fileType
    }))

    const beforeUpload = (file) => {
      if (!props.fileType) {
        ElMessage.error('文件类型未指定')
        return false
      }
      const isImage = file.type.startsWith('image/')
      const isVideo = file.type.startsWith('video/')
      const isValidType = props.accept === 'image/*' ? isImage : 
                          props.accept === 'video/*' ? isVideo : 
                          props.accept ? file.type === props.accept : true
      const isLtMaxSize = file.size / 1024 / 1024 < props.maxSize

      if (!isValidType) {
        ElMessage.error('上传文件类型不正确!')
      }
      if (!isLtMaxSize) {
        ElMessage.error(`上传文件大小不能超过 ${props.maxSize}MB!`)
      }

      return isValidType && isLtMaxSize
    }

    const handleExceed = (files, fileList) => {
      ElMessage.warning(`当前限制选择 ${props.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    }

    return {
      apiBaseUrl,
      uploadHeaders,
      fileList,
      handleUploadSuccess,
      uploadData,
      beforeUpload,
      handleExceed
    }
  }
}
</script>
