<template>
  <div class="baby-field-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="字段名称">
              <el-input v-model="searchForm.fieldName" placeholder="搜索字段名称"></el-input>
            </el-form-item>
            <el-form-item label="宝宝名称">
              <el-input v-model="searchForm.babyName" placeholder="搜索宝宝名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchBabyFields">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openDialog()">新增儿童字段</el-button>
        </div>
      </div>
    </el-card>

    <el-card class="baby-field-list">
      <el-table v-loading="loading" :data="babyFields" style="width: 100%">
        <el-table-column prop="babyName" label="宝宝名称"></el-table-column>
        <el-table-column prop="fieldName" label="字段名称"></el-table-column>
        <el-table-column prop="value" label="字段值"></el-table-column>
        <el-table-column prop="enable" label="状态">
          <template #default="scope">
            <el-tag :type="scope.row.enable ? 'success' : 'danger'">
              {{ scope.row.enable ? '启用' : '禁用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button link type="primary" @click="editBabyField(scope.row)">编辑</el-button>
            <el-button link type="danger" @click="deleteBabyField(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 儿童字段编辑对话框 -->
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="babyFieldForm" label-width="100px" :rules="rules" ref="babyFieldFormRef">
        <el-form-item label="宝宝" prop="babyId">
          <el-select v-model="babyFieldForm.babyId" placeholder="选择宝宝">
            <el-option
              v-for="baby in babies"
              :key="baby.id"
              :label="baby.name"
              :value="baby.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字段" prop="fieldId">
          <el-select v-model="babyFieldForm.fieldId" placeholder="选择字段">
            <el-option
              v-for="field in fields"
              :key="field.id"
              :label="field.name"
              :value="field.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字段值" prop="value">
          <el-input v-model="babyFieldForm.value"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="enable">
          <el-switch v-model="babyFieldForm.enable" active-text="启用" inactive-text="禁用"></el-switch>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitBabyField">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'

export default {
  name: 'BabyFieldManagement',
  setup() {
    const loading = ref(false)
    const babyFields = ref([])
    const babies = ref([])
    const fields = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增儿童字段')
    const babyFieldFormRef = ref(null)

    const searchForm = reactive({
      fieldName: '',
      babyName: ''
    })

    const babyFieldForm = reactive({
      id: '',
      babyId: '',
      fieldId: '',
      value: '',
      enable: true
    })

    const rules = {
      babyId: [{ required: true, message: '请选择宝宝', trigger: 'change' }],
      fieldId: [{ required: true, message: '请选择字段', trigger: 'change' }],
      value: [{ required: true, message: '请输入字段值', trigger: 'blur' }]
    }

    const searchBabyFields = async () => {
      loading.value = true
      try {
        const params = {
          field_name: searchForm.fieldName,
          baby_name: searchForm.babyName,
          page: currentPage.value,
          page_size: pageSize.value
        }
        const res = await get('/auth/admin/baby-fields', params)
        babyFields.value = res.babyFields
        total.value = res.total
      } catch (error) {
        ElMessage.error('获取儿童字段列表失败')
      } finally {
        loading.value = false
      }
    }

    const resetSearch = () => {
      searchForm.fieldName = ''
      searchForm.babyName = ''
      searchBabyFields()
    }

    const fetchBabies = async () => {
      try {
        const res = await get('/auth/baby/babies', { page: 1, page_size: 1000 })
        babies.value = res.babies
      } catch (error) {
        ElMessage.error('获取宝宝列表失败')
      }
    }

    const fetchFields = async () => {
      try {
        const res = await get('/auth/admin/fields', { include_disabled: true, page: 1, page_size: 1000 })
        fields.value = res.fields
      } catch (error) {
        ElMessage.error('获取字段列表失败')
      }
    }

    const openDialog = () => {
      dialogTitle.value = '新增儿童字段'
      Object.assign(babyFieldForm, {
        id: '',
        babyId: '',
        fieldId: '',
        value: '',
        enable: true
      })
      dialogVisible.value = true
    }

    const editBabyField = (row) => {
      dialogTitle.value = '编辑儿童字段'
      Object.assign(babyFieldForm, {
        id: row.id,
        babyId: row.baby_id,
        fieldId: row.field_id,
        value: row.value,
        enable: row.enable
      })
      dialogVisible.value = true
    }

    const deleteBabyField = (row) => {
      ElMessageBox.confirm('确定要删除这个儿童字段吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/baby-field/${row.id}`)
          ElMessage.success('删除成功')
          searchBabyFields()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const submitBabyField = async () => {
      try {
        await babyFieldFormRef.value.validate()
        if (babyFieldForm.id) {
          // 更新儿童字段
          await put(`/auth/admin/baby-field/${babyFieldForm.id}`, {
            baby_id: babyFieldForm.babyId,
            field_id: babyFieldForm.fieldId,
            value: babyFieldForm.value,
            enable: babyFieldForm.enable
          })
          ElMessage.success('更新成功')
        } else {
          // 创建儿童字段
          await post('/auth/admin/baby-field', {
            baby_id: babyFieldForm.babyId,
            field_id: babyFieldForm.fieldId,
            value: babyFieldForm.value,
            enable: babyFieldForm.enable
          })
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchBabyFields()
      } catch (error) {
        ElMessage.error('操作失败')
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      currentPage.value = 1
      searchBabyFields()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchBabyFields()
    }

    onMounted(async () => {
      await fetchBabies()
      await fetchFields()
      await searchBabyFields()
    })

    return {
      loading,
      babyFields,
      babies,
      fields,
      searchForm,
      searchBabyFields,
      resetSearch,
      dialogVisible,
      dialogTitle,
      babyFieldForm,
      babyFieldFormRef,
      rules,
      openDialog,
      editBabyField,
      deleteBabyField,
      submitBabyField,
      currentPage,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange
    }
  }
}
</script>

<style scoped>
.baby-field-management {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.baby-field-list {
  flex-grow: 1;
  overflow-y: auto;
}
.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
</style> 