import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/login.vue';
import Layout from '@/components/Layout.vue';
import TopicManagement from '@/views/TopicManagement.vue';
import ClassifyManagement from '@/views/ClassifyManagement.vue';
import FieldManagement from '@/views/baby/FieldManagement.vue';
import FieldPropertyManagement from '@/views/baby/FieldPropertyManagement.vue';
import BabyFieldManagement from '@/views/baby/BabyFieldManagement.vue';
import UserManagement from '@/views/UserManagement.vue';
import RoleManagement from '@/views/RoleManagement.vue';
import PermissionList from '@/views/PermissionList.vue';
import SystemSettings from '@/views/SystemSettings.vue';

const routes = [
  {
    path: '/',
    component: Layout,
    name: 'Home',
    children: [
      {
        path: 'topics',
        name: 'TopicManagement',
        component: TopicManagement
      },
      {
        path: 'classify',
        name: 'ClassifyManagement',
        component: ClassifyManagement
      },
      {
        path: 'users',
        name: 'UserManagement',
        component: UserManagement
      },
      {
        path: 'roles',
        name: 'RoleManagement',
        component: RoleManagement
      },
      {
        path: 'permissions',
        name: 'PermissionList',
        component: PermissionList
      },
      {
        path: 'settings',
        name: 'SystemSettings',
        component: SystemSettings
      },
      {
        path: 'baby/fields',
        name: 'FieldManagement',
        component: FieldManagement,
      },
      {
        path: 'baby/field-properties',
        name: 'FieldPropertyManagement',
        component: FieldPropertyManagement,
      },
      {
        path: 'baby/baby-fields',
        name: 'BabyFieldManagement',
        component: BabyFieldManagement,
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];
console.log(routes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


// 在这里添加导航守卫
router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const isLoggedIn = userInfo;

  if (!isLoggedIn) {
      if (to.name !== 'Login' && to.name !== 'register') {  // 避免无限重定向
      console.log('Redirecting to login');
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    // 其他情况则放行
    next();
  }
});

export default router;
