<template>
  <div class="user-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="用户名">
              <el-input v-model="searchForm.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="searchForm.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item label="角色">
              <el-select v-model.number="searchForm.role" placeholder="选择角色">
                <el-option v-for="role in roles" :key="role.Value" :label="role.Key" :value="role.Value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchUsers">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openCreateDialog">新增用户</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-table :data="users" style="width: 100%">
        <el-table-column prop="base_info.id" label="ID" width="120"></el-table-column>
        <el-table-column label="头像" width="80">
          <template #default="scope">
            <el-avatar :size="40"
              :src="scope.row.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'">
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>
        <el-table-column prop="identity" label="角色">
          <template #default="scope">
            {{ getRoleName(scope.row.identity) }}
          </template>
        </el-table-column>
        <el-table-column prop="enable" label="状态">
          <template #default="scope">
            <el-tag :type="scope.row.enable ? 'success' : 'danger'">
              {{ scope.row.enable ? '启用' : '禁用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button size="small" @click="editUser(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteUser(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :total="total"
          :page-sizes="[10, 20, 50, 100]" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增/编辑用户对话框 -->
    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="60%">
      <el-form ref="userFormRef" :model="userForm" :rules="rules" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="用户名" prop="userName">
              <el-input v-model="userForm.userName"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="昵称" prop="nickName">
              <el-input v-model="userForm.nickName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="密码" prop="password" :rules="passwordRules">
              <el-input v-model="userForm.password" type="password"
                :placeholder="userForm.id ? '不修改请留空' : '请输入密码'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="userForm.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="userForm.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="生日" prop="birthday">
              <el-date-picker v-model="userForm.birthday" type="date" placeholder="选择生日"
                :default-time="new Date(2000, 0, 1)" value-format="x"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="gender">
              <el-radio-group v-model="userForm.gender">
                <el-radio label="male">男</el-radio>
                <el-radio label="female">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="城市" prop="city">
              <el-cascader v-model="userForm.city" :options="cityOptions" placeholder="请选择城市"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色" prop="roleId">
              <el-select v-model="userForm.roleId">
                <el-option v-for="role in roles" :key="role.Value" :label="role.Key" :value="role.Value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="头像">
              <el-upload class="avatar-uploader" action="/api/upload" :show-file-list="false"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="userForm.avatarPath" :src="userForm.avatarPath" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="账号状态">
              <el-switch v-model="userForm.enable" active-text="启用" inactive-text="禁用"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUser">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { post, put, del } from '@/utils/request'
import { USER_IDENTITY_LIST, hasIdentity, isSuperAdmin } from '@/utils/constants'
import utils from '@/utils'

export default {
  name: 'UserManagement',
  setup() {
    const searchForm = reactive({
      username: '',
      phone: '',
      email: '',
      role: 0
    })
    const users = ref([])
    const roles = ref(USER_IDENTITY_LIST)
    const allPermissions = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增用户')
    const userForm = reactive({
      id: null,
      userName: '',
      password: '',
      phone: '',
      email: '',
      roleId: 0,
      nickName: '',
      birthday: '',
      gender: '',
      city: '',
      avatarPath: '',
      enable: true,
      weights: 0,
      status: 0
    })
    const userFormRef = ref(null)

    const rules = {
      userName: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
        { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
      ],
      phone: [
        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
      ],
      email: [
        { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
      ],
      roleId: [
        { required: true, message: '请选择角色', trigger: 'change' }
      ],
      nickName: [
        { max: 50, message: '昵称最长50个字符', trigger: 'blur' }
      ]
    }

    const searchUsers = async () => {
      try {
        const res = await post('/auth/query_users', {
          username: searchForm.username,
          phone: searchForm.phone,
          email: searchForm.email,
          identity: searchForm.role ? parseInt(searchForm.role) : undefined,
          page: currentPage.value,
          size: pageSize.value
        })
        users.value = res.data.list
        total.value = res.data.total
      } catch (error) {
        ElMessage.error('获取用户列表失败')
      }
    }

    const resetSearch = () => {
      Object.assign(searchForm, {
        username: '',
        phone: '',
        email: '',
        role: 0
      })
      searchUsers()
    }

    const resetForm = () => {
      Object.assign(userForm, {
        id: null,
        userName: '',
        password: '',
        phone: '',
        email: '',
        roleId: 0,
        nickName: '',
        birthday: '',
        gender: '',
        city: '',
        avatarPath: '',
        enable: true,
        weights: 0,
        status: 0
      })
    }

    const openCreateDialog = () => {
      dialogTitle.value = '新增用户'
      resetForm()
      dialogVisible.value = true
    }

    const editUser = (row) => {
      console.log('编辑的用户数据:', row)
      dialogTitle.value = '编辑用户'
      Object.assign(userForm, {
        id: row.base_info.id,
        userName: row.username,
        password: '',
        phone: row.phone || '',
        email: row.email || '',
        roleId: row.identity || 0,
        nickName: row.nickname || '',
        birthday: row.birthday || '',
        gender: row.gender || '',
        city: row.city || '',
        avatarPath: row.avatar || '',
        enable: typeof row.enable === 'boolean' ? row.enable : true,
        weights: row.weights || 0,
        status: row.status || 0
      })
      console.log('表单数据:', userForm)
      dialogVisible.value = true
    }

    const deleteUser = (row) => {
      ElMessageBox.confirm('确定要删除这个用户吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/user/${row.base_info.id}`)
          ElMessage.success('删除成功')
          searchUsers()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => { })
    }

    const submitUser = async () => {
      const valid = await userFormRef.value.validate()
      if (!valid) return
      
      try {
        // 构建基础的用户数据
        const userData = {
          username: userForm.userName,
          nickname: userForm.nickName,
          phone: userForm.phone,
          email: userForm.email,
          identity: parseInt(userForm.roleId),
          avatar: userForm.avatarPath,
          city: userForm.city,
          birthday: userForm.birthday ? parseInt(userForm.birthday) : 0,
          gender: userForm.gender,
          enable: userForm.enable,
          weights: userForm.weights,
          status: userForm.status,
          // 设置默认值
          open_id: '',
          ic: '',
          inviter: '',
          last_check_in: 0,
          continuous_check_in: 0,
          permission: 0,
          balance: 0,
          last_active_time: 0,
          register_source: 0
        }

        if (userForm.id) {
          // 更新用户
          console.log('更新用户ID:', userForm.id)
          const updateData = {
            id: userForm.id,
            operator_id: utils.getCurrentUserId(),
            user: userData
          }
          // 如果有输入新密码，则添加密码字段
          if (userForm.password) {
            updateData.password = userForm.password
          }
          
          await put(`/auth/user/${userForm.id}`, updateData)
          ElMessage.success('更新成功')
        } else {
          // 创建新用户
          console.log('创建新用户')
          const createData = {
            username: userForm.userName, // 创建时用户名单独提供
            password: userForm.password, // 创建时密码单独提供
            operator_id: utils.getCurrentUserId(),
            user: userData
          }
          
          await post('/auth/user', createData)
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchUsers()
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const canDeleteUser = (user) => {
      return isSuperAdmin() || (hasIdentity(user.identity))
    }

    const getRoleName = (identity) => {
      const role = USER_IDENTITY_LIST.find(r => r.Value === identity)
      return role ? role.Key : '未知角色'
    }

    const handleAvatarSuccess = (res) => {
      userForm.avatarPath = res.data.url // 假设上传成功后返回的是图片URL
    }

    const beforeAvatarUpload = (file) => {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        ElMessage.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        ElMessage.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }

    const passwordRules = computed(() => {
      return userForm.id ?
        [
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ] :
        [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ]
    })

    onMounted(() => {
      searchUsers()
    })

    watch([currentPage, pageSize], () => {
      searchUsers()
    })

    return {
      searchForm,
      users,
      roles,
      allPermissions,
      currentPage,
      pageSize,
      total,
      dialogVisible,
      dialogTitle,
      userForm,
      searchUsers,
      resetSearch,
      openCreateDialog,
      editUser,
      deleteUser,
      submitUser,
      canDeleteUser,
      hasIdentity,
      userFormRef,
      rules,
      getRoleName,
      handleAvatarSuccess,
      beforeAvatarUpload,
      resetForm,
      passwordRules,
    }
  }
}
</script>

<style scoped>
.user-management {
  height: 100%;
}

.mb-4 {
  margin-bottom: 1rem;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-area {
  flex-grow: 1;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-area {
  display: flex;
  gap: 0.5rem;
}

.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
  line-height: 178px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>