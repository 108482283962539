<template>
  <div class="field-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="字段名称">
              <el-input v-model="searchForm.name" placeholder="搜索字段名称"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="searchForm.enable" placeholder="选择状态">
                <el-option label="全部" :value="''"></el-option>
                <el-option label="启用" :value="true"></el-option>
                <el-option label="禁用" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchFields">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openDialog()">新增字段</el-button>
        </div>
      </div>
    </el-card>

    <el-card class="field-list">
      <el-table v-loading="loading" :data="fields" style="width: 100%">
        <el-table-column prop="name" label="字段名称"></el-table-column>
        <el-table-column prop="show_name" label="显示名称">
          <template #default="scope">
            {{ scope.row.show_name || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="field_type" label="字段类型">
          <template #default="scope">
            {{ getTopicTypeName(scope.row.field_type) }}
          </template>
        </el-table-column>
        <el-table-column prop="required" label="是否必填">
          <template #default="scope">
            <el-tag :type="scope.row.required ? 'danger' : 'info'">
              {{ scope.row.required ? '是' : '否' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="default_value" label="默认值">
          <template #default="scope">
            <span>{{ scope.row.default_value || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="描述" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.description || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="enable" label="状态">
          <template #default="scope">
            <el-tag :type="scope.row.enable ? 'success' : 'danger'">
              {{ scope.row.enable ? '启用' : '禁用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="property" label="属性"></el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button link type="primary" @click="editField(scope.row)">编辑</el-button>
            <el-button link type="danger" @click="deleteField(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <!-- 字段编辑对话框 -->
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :before-close="handleDialogClose"
    >
      <el-scrollbar height="60vh">
        <el-form :model="fieldForm" label-width="100px" :rules="rules" ref="fieldFormRef">
          <el-form-item label="字段名称" prop="name">
            <el-input 
              v-model="fieldForm.name" 
              :placeholder="'仅支持英文、数字和下划线'"
              :disabled="!!fieldForm.old_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="显示名称" prop="show_name">
            <el-input v-model="fieldForm.show_name" placeholder="请输入显示名称"></el-input>
          </el-form-item>
          <el-form-item label="字段类型" prop="field_type">
            <el-select v-model="fieldForm.field_type" placeholder="选择字段类型" @change="handleFieldTypeChange">
              <el-option
                v-for="type in topicTypes"
                :key="type.value"
                :label="type.key"
                :value="type.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- 选择题选项 -->
          <template v-if="isChoiceField">
            <el-divider content-position="left">选项设置</el-divider>
            <el-form-item
              v-for="(option, index) in fieldForm.option_list"
              :key="index"
              :label="`选项 ${index + 1}`"
            >
              <div class="option-row">
                <el-input 
                  v-model="option.option" 
                  placeholder="选项内容"
                  style="width: 60%;"
                ></el-input>
                <el-select v-model="option.option_type" style="width: 30%;">
                  <el-option
                    v-for="type in optionTypes"
                    :key="type.value"
                    :label="type.key"
                    :value="type.value"
                  ></el-option>
                </el-select>
                <el-button @click="removeOption(index)" type="danger" icon="Delete">删除</el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button @click="addOption" type="primary" icon="Plus">添加选项</el-button>
            </el-form-item>
          </template>

          <el-form-item label="是否必填" prop="required">
            <el-switch v-model="fieldForm.required"></el-switch>
          </el-form-item>
          <el-form-item label="默认值" prop="default_value">
            <el-input v-model="fieldForm.default_value"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input type="textarea" v-model="fieldForm.description"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="enable">
            <el-switch v-model="fieldForm.enable"></el-switch>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input-number v-model="fieldForm.sort" :min="0"></el-input-number>
          </el-form-item>
          <el-form-item label="属性" prop="property">
            <el-select v-model="fieldForm.property" placeholder="选择属性">
              <el-option
                v-for="item in allFieldProperties"
                :key="item.key"
                :label="item.key"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleDialogClose">取 消</el-button>
          <el-button type="primary" @click="submitField">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'
import { getConstantByKey } from '@/utils/constants'

export default {
  name: 'FieldManagement',
  setup() {
    // 添加 fieldFormRef 的定义
    const fieldFormRef = ref(null)
    // 状态定义
    const loading = ref(false)
    const fields = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增字段')
    const allFieldProperties = ref([]) // 所有可选的字段属性列表
    const topicTypes = ref(getConstantByKey('topicTypes')?.types || [])
    const optionTypes = ref(getConstantByKey('topicOptionTypes')?.types || [])

    // 表单数据
    const searchForm = reactive({
      name: '',
      enable: ''
    })

    const fieldForm = reactive({
      old_name: '',
      name: '',
      show_name: '',
      required: false,
      default_value: '',
      description: '',
      enable: true,
      sort: 0,
      property: '',
      field_type: '',
      option_list: [],
      name_form: ''
    })

    // 表单验证规则
    const rules = {
      name: [
        { required: true, message: '请输入字段名称', trigger: 'blur' },
        { pattern: /^[a-zA-Z0-9_]+$/, message: '仅支持英文、数字和下划线', trigger: 'blur' }
      ],
      show_name: [
        { required: true, message: '请输入显示名称', trigger: 'blur' }
      ],
      field_type: [
        { required: true, message: '请选择字段类型', trigger: 'change' }
      ]
    }

    // 方法定义
    const searchFields = async () => {
      loading.value = true
      try {
        const params = {
          include_disabled: searchForm.enable === '' ? true : searchForm.enable,
          page: currentPage.value,
          page_size: pageSize.value,
          name: searchForm.name
        }
        const res = await get('/auth/admin/fields', params)
        if (res.data && res.data.fields) {
          fields.value = res.data.fields
          total.value = res.data.total
        } else {
          fields.value = []
          total.value = 0
        }
      } catch (error) {
        console.error('获取字段列表失败:', error)
        ElMessage.error('获取字段列表失败')
        fields.value = []
        total.value = 0
      } finally {
        loading.value = false
      }
    }

    const resetSearch = () => {
      searchForm.name = ''
      searchForm.enable = ''
      searchFields()
    }

    const fetchAllFieldProperties = async () => {
      try {
        const res = await get('/auth/admin/field_properties', { page: 1, page_size: 1000 })
        allFieldProperties.value = res.properties
      } catch (error) {
        ElMessage.error('获取字段属性列表失败')
      }
    }

    const openDialog = async () => {
      dialogTitle.value = '新增字段'
      Object.assign(fieldForm, {
        old_name: '',
        name: '',
        type: '',
        required: false,
        defaultValue: '',
        description: '',
        enable: true,
        sort: 0,
        property: ''
      })
      await fetchAllFieldProperties()
      dialogVisible.value = true
    }

    const editField = (row) => {
      dialogTitle.value = '编辑字段'
      Object.assign(fieldForm, {
        old_name: row.name,
        name: row.name,
        show_name: row.show_name,
        field_type: row.field_type,
        required: row.required,
        default_value: row.default_value,
        description: row.description,
        enable: row.enable,
        sort: row.sort,
        property: row.property,
        option_list: row.option_list || [],
        name_form: row.name_form
      })
      dialogVisible.value = true
    }

    const deleteField = (row) => {
      ElMessageBox.confirm('确定要删除这个字段吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/field/${encodeURIComponent(row.name)}`)
          ElMessage.success('删除成功')
          searchFields()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const submitField = async () => {
      try {
        await fieldFormRef.value.validate()
        
        const fieldData = {
          name: fieldForm.name,
          show_name: fieldForm.show_name,
          required: fieldForm.required,
          default_value: fieldForm.default_value,
          description: fieldForm.description,
          enable: fieldForm.enable,
          sort: fieldForm.sort,
          property: fieldForm.property,
          field_type: fieldForm.field_type,
          option_list: fieldForm.option_list,
          name_form: fieldForm.name_form
        }

        if (fieldForm.old_name) {
          // 更新字段
          const updateData = {
            old_name: fieldForm.old_name,
            ...fieldData
          }
          await put(`/auth/admin/field/${encodeURIComponent(fieldForm.old_name)}`, updateData)
          ElMessage.success('更新成功')
        } else {
          // 创建字段
          await post('/auth/admin/field', fieldData)
          ElMessage.success('创建成功')
        }
        
        dialogVisible.value = false
        searchFields()
      } catch (error) {
        console.error('提交失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      currentPage.value = 1
      searchFields()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchFields()
    }

    // 添加 getTopicTypeValue 函数
    const getTopicTypeValue = (typeName) => {
      const typesData = getConstantByKey('topicTypes')
      if (!typesData || !typesData.types) return null
      
      const type = typesData.types.find(t => t.key === typeName)
      return type ? type.value : null
    }

    // 判断是否为选择题类型
    const isChoiceField = computed(() => {
      return fieldForm.field_type === getTopicTypeValue('单选题') || 
             fieldForm.field_type === getTopicTypeValue('多选题')
    })

    // 处理字段类型变化
    const handleFieldTypeChange = () => {
      if (isChoiceField.value) {
        if (!fieldForm.option_list.length) {
          addOption()
        }
      } else {
        fieldForm.option_list = []
      }
    }

    // 添加选项
    const addOption = () => {
      fieldForm.option_list.push({
        option: '',
        option_type: 1,
        option_content: ''
      })
    }

    // 删除选项
    const removeOption = (index) => {
      fieldForm.option_list.splice(index, 1)
    }

    // 添加对话框关闭处理
    const handleDialogClose = (done) => {
      ElMessageBox.confirm('确定要关闭吗？未保存的内容将会丢失。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        dialogVisible.value = false
        if (done) done()
      }).catch(() => {})
    }

    const getTopicTypeName = (value) => {
      const typesData = getConstantByKey('topicTypes')
      if (!typesData || !typesData.types) return '未知类型'
      
      const type = typesData.types.find(t => t.value === value)
      return type ? type.key : '未知类型'
    }

    onMounted(() => {
      searchFields()
    })

    return {
      loading,
      fields,
      searchForm,
      searchFields,
      resetSearch,
      dialogVisible,
      dialogTitle,
      fieldForm,
      fieldFormRef,
      rules,
      openDialog,
      editField,
      deleteField,
      submitField,
      currentPage,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange,
      allFieldProperties,
      topicTypes,
      optionTypes,
      isChoiceField,
      handleFieldTypeChange,
      addOption,
      removeOption,
      handleDialogClose,
      getTopicTypeValue,
      getTopicTypeName
    }
  }
}
</script>

<style scoped>
.field-management {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mb-4 {
  margin-bottom: 1rem;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-area {
  flex-grow: 1;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-area {
  display: flex;
  gap: 0.5rem;
}

.field-list {
  flex-grow: 1;
  overflow-y: auto;
}

.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.option-row {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.el-divider {
  margin: 24px 0;
}
</style> 