<template>
  <div class="topic-management">
    <el-container>
      <!-- 左侧树形结构 -->
      <el-aside width="360px" class="tree-aside">
        <el-card class="tree-card" :body-style="{ padding: '0px' }">
          <el-scrollbar>
            <el-tree
              ref="treeRef"
              :data="classifyTree"
              :props="defaultProps"
              node-key="id"
              :expand-on-click-node="false"
              highlight-current
              lazy
              :load="loadNode"
              @node-click="handleNodeClick"
              class="custom-tree"
            >
              <template #default="{ node, data }">
                <div class="custom-tree-node">
                  <el-icon v-if="data.node_type === NODE_TYPES.PAPER">
                    <Document />
                  </el-icon>
                  <el-icon v-else-if="data.node_type === NODE_TYPES.PROJECT">
                    <Folder />
                  </el-icon>
                  <el-icon v-else>
                    <FolderOpened />
                  </el-icon>
                  <span :class="{ 'node-text': true, 'is-paper': data.node_type === NODE_TYPES.PAPER }">
                    {{ node.label }}
                  </span>
                </div>
              </template>
            </el-tree>
          </el-scrollbar>
        </el-card>
      </el-aside>

      <!-- 右侧内容区 -->
      <el-main>
        <!-- 面包屑导航 -->
        <el-breadcrumb v-if="currentPath.length > 0" class="mb-4">
          <el-breadcrumb-item 
            v-for="item in currentPath" 
            :key="item.id"
            class="breadcrumb-item"
            @click.prevent="handleBreadcrumbClick(item)"
          >
            {{ item.name }}
          </el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 原有的搜索和列表内容 -->
        <el-card class="mb-4">
          <div class="top-bar">
            <div class="search-area">
              <el-form :inline="true" :model="searchForm" class="search-form">
                <el-form-item label="标题" style="width: 200px;">
                  <el-input v-model="searchForm.title" placeholder="题目标题"></el-input>
                </el-form-item>
                <el-form-item label="题目类型" style="width: 200px;">
                  <el-select v-model="searchForm.topic_type" placeholder="选择题目类型">
                    <el-option
                      v-for="type in topicTypes"
                      :key="type.value"
                      :label="type.key"
                      :value="type.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="分类ID" style="width: 200px;">
                  <el-input v-model="searchForm.classify_id" placeholder="分类ID"></el-input>
                </el-form-item>
                <el-form-item label="状态" style="width: 200px;">
                  <el-select v-model="searchForm.status" placeholder="选择状态">
                    <el-option label="启用" :value="1"></el-option>
                    <el-option label="禁用" :value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="button-area">
              <el-button type="primary" @click="searchTopics">查询</el-button>
              <el-button @click="resetSearch">重置</el-button>
              <el-button type="success" @click="openCreateDialog">新增题目</el-button>
            </div>
          </div>
        </el-card>

    <el-card class="topic-list-card">
      <el-table v-if="topics && topics.length > 0" :data="topics" style="width: 100%">
        <el-table-column prop="title" label="题目名称"></el-table-column>
        <el-table-column prop="topic_type" label="题目类型">
          <template #default="scope">
            {{ getTopicTypeName(scope.row.topic_type) }}
          </template>
        </el-table-column>
        <el-table-column label="所属分类">
          <template #default="scope">
            <el-tag
              v-for="classify in scope.row.classify_list"
              :key="classify.classify_id"
              class="mr-1 mb-1"
            >
              {{ classify.classify_name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="题目选项">
          <template #default="scope">
            <el-button 
              v-if="scope.row.option_list && scope.row.option_list.length > 0"
              link 
              type="primary" 
              @click="showOptions(scope.row.option_list)"
            >
              查看选项
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            <el-tag :type="scope.row.status === 1 ? 'success' : 'danger'">
              {{ scope.row.status === 1 ? '启用' : '禁用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间">
          <template #default="scope">
            {{ formatDate(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button link type="primary" @click="showMoreInfo(scope.row)">更多信息</el-button>
            <el-button link type="primary" @click="editTopic(scope.row)">编辑</el-button>
            <el-button link type="danger" @click="deleteTopic(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-empty v-else description="暂无数据"></el-empty>
    </el-card>

    <div class="pagination-container">
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 新增/编辑题目对话框 -->
    <el-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleDialogClose"
    >
      <el-scrollbar height="60vh">
        <el-form :model="topicForm" label-width="120px">
          <el-divider content-position="left">基本信息</el-divider>
          <el-form-item label="标题" required>
            <el-input v-model="topicForm.title"></el-input>
          </el-form-item>

          <el-divider content-position="left">项目信息</el-divider>
          <el-form-item label="题目类型" required>
            <el-select v-model="topicForm.topic_type" @change="handleTopicTypeChange">
              <el-option
                v-for="type in topicTypes"
                :key="type.value"
                :label="type.key"
                :value="type.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 所属项目选择 -->
          <el-form-item label="所属项目" required>
            <div class="project-selection">
              <el-cascader
                v-model="selectedClassify"
                :options="classifyTree"
                :props="{
                  checkStrictly: true,
                  emitPath: false,
                  label: 'name',
                  value: 'id',
                  lazy: true,
                  lazyLoad: handleCascaderLazyLoad,
                }"
                clearable
                @change="handleClassifyChange"
                placeholder="请选择所属项目"
              ></el-cascader>
              <div v-if="topicForm.classify_id" class="selected-classify">
                当前选择: {{ getClassifyName(topicForm.classify_id) }}
              </div>
            </div>
          </el-form-item>

          <!-- 选择题和判断题的选项 -->
          <template v-if="topicForm.topic_type === getTopicTypeValue('单选题') || topicForm.topic_type === getTopicTypeValue('多选题') || topicForm.topic_type === getTopicTypeValue('判断题')">
            <el-form-item
              v-for="(option, index) in topicForm.options"
              :key="index"
              :label="`选项 ${index + 1}`"
            >
              <div class="option-row">
                <el-input 
                  v-model="option.option" 
                  style="width: 40%;"
                  :placeholder="topicForm.topic_type === getTopicTypeValue('判断题') ? '是/否' : '选项内容'"
                  :disabled="topicForm.topic_type === getTopicTypeValue('判断题')"
                ></el-input>
                <el-select 
                  v-model="option.option_type" 
                  style="width: 20%;"
                  :disabled="topicForm.topic_type === getTopicTypeValue('判断题')"
                >
                  <el-option
                    v-for="type in optionTypes"
                    :key="type.value"
                    :label="type.key"
                    :value="type.value"
                  ></el-option>
                </el-select>
                <el-input-number 
                  v-model="option.score" 
                  :min="0"
                  :max="100"
                  style="width: 30%;"
                  placeholder="选项分数"
                ></el-input-number>
                <el-button 
                  @click="removeOption(index)" 
                  type="danger" 
                  icon="el-icon-delete"
                  :disabled="topicForm.topic_type === getTopicTypeValue('判断题')"
                ></el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button 
                @click="addOption" 
                type="primary" 
                icon="el-icon-plus"
                v-if="topicForm.topic_type !== getTopicTypeValue('判断题')"
              >添加选项</el-button>
            </el-form-item>
          </template>
          
          <!-- 打分题的分数范围 -->
          <el-form-item v-if="topicForm.topic_type === getTopicTypeValue('打分制题')" label="分数范围">
            <div v-if="dialogVisible">
              <el-slider
                v-model="topicForm.score_range"
                range
                :min="0"
                :max="3000"
                :step="1"
                show-input
                input-size="small"
                style="width: 200px;"
                @change="updateScoreRange"
              ></el-slider>
            </div>
          </el-form-item>
          
          <el-divider content-position="left">附加资源</el-divider>
          <!-- 附件上传区域 -->
          <el-collapse v-model="activeCollapse">
            <el-collapse-item title="附件上传" name="attachments">
              <el-form-item label="上传图片">
                <FileUpload
                  :fileType="getFileTypeValue('封面')"
                  buttonText="上传图片"
                  accept="image/*"
                  :limit="1"
                  :maxSize="10"
                  tip="支持常见图片格式，大小不超过10MB"
                  @upload-success="handlePicUploadSuccess"
                  :fileList="picFileList"
                />
                <el-image v-if="topicForm.pic" :src="topicForm.pic" style="width: 100px; height: 100px; margin-top: 10px;"></el-image>
              </el-form-item>
              <el-form-item label="上传视频">
                <FileUpload
                  :fileType="getFileTypeValue('题目视频')"
                  buttonText="上传视频"
                  accept="video/*"
                  :limit="1"
                  :maxSize="100"
                  tip="支持常见视频格式，大小不超过100MB"
                  @upload-success="handleVideoUploadSuccess"
                />
                <video v-if="topicForm.video" :src="topicForm.video" controls style="max-width: 300px;"></video>
              </el-form-item>
              <el-form-item label="上传音频">
                <el-upload
                  :action="`${apiBaseUrl}/auth/file/upload`"
                  :data="{ fileType: getFileTypeValue('题目音频') }"
                  :on-success="handleAudioUploadSuccess"
                  :before-upload="beforeUpload"
                  :file-list="audioFileList"
                  :headers="uploadHeaders"
                >
                  <el-button icon="el-icon-upload">点击上传</el-button>
                </el-upload>
                <audio v-if="topicForm.audio" :src="topicForm.audio" controls></audio>
              </el-form-item>
              <el-form-item label="上传文件">
                <el-upload
                  :action="`${apiBaseUrl}/auth/file/upload`"
                  :data="{ fileType: getFileTypeValue('题目文件') }"
                  :on-success="handleFileUploadSuccess"
                  :before-upload="beforeUpload"
                  :file-list="fileFileList"
                  :headers="uploadHeaders"
                >
                  <el-button icon="el-icon-upload">点击上传</el-button>
                </el-upload>
                <div v-if="topicForm.file">
                  已上传文件：<a :href="topicForm.file" target="_blank">查看文件</a>
                </div>
              </el-form-item>
            </el-collapse-item>
          </el-collapse>

          <!-- 标签管理 -->
          <el-form-item label="题目标签">
            <div class="tag-input-area">
              <div class="single-tag-input">
                <el-input 
                  v-model="topicForm.currentTag" 
                  placeholder="输入标签"
                  @keyup.enter="addTag"
                >
                </el-input>
                <el-button @click="addTag">添加</el-button>
              </div>
              
              <el-collapse>
                <el-collapse-item title="批量添加标签">
                  <el-input
                    type="textarea"
                    v-model="batchTags"
                    placeholder="多个标签用英文逗号分隔"
                    @change="handleBatchTags"
                  ></el-input>
                </el-collapse-item>
              </el-collapse>
              
              <div class="tag-display">
                <el-tag
                  v-for="tag in topicForm.tagList"
                  :key="tag"
                  closable
                  @close="removeTag(tag)"
                  class="mx-1"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </div>
          </el-form-item>

          <el-divider content-position="left">其他设置</el-divider>
          <el-form-item label="状态">
            <el-switch
              v-model="topicForm.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
            ></el-switch>
          </el-form-item>
          <el-form-item label="权重">
            <el-input-number v-model="topicForm.weight" :min="0" :max="100"></el-input-number>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleDialogClose">取 消</el-button>
          <el-button type="primary" @click="submitTopic">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 查看更多信息对话框 -->
    <el-dialog
      title="题目详细信息"
      v-model="moreInfoDialogVisible"
      width="60%"
    >
      <el-descriptions :column="2" border>
        <el-descriptions-item label="题目ID">{{ currentTopic.id }}</el-descriptions-item>
        <el-descriptions-item label="题目标题">{{ currentTopic.title }}</el-descriptions-item>
        <el-descriptions-item label="题目类型">{{ getTopicTypeName(currentTopic.topic_type) }}</el-descriptions-item>
        <el-descriptions-item label="题目内容">{{ currentTopic.content }}</el-descriptions-item>
        <el-descriptions-item label="所属分类">
          <el-tag
            v-for="classify in currentTopic.classify_list"
            :key="classify.classify_id"
            class="mr-1 mb-1"
          >
            {{ classify.classify_name }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="分数范围">{{ currentTopic.score_range }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ formatDate(currentTopic.created_at) }}</el-descriptions-item>
        <el-descriptions-item label="更新时间">{{ formatDate(currentTopic.updated_at) }}</el-descriptions-item>
        <el-descriptions-item label="状态">{{ currentTopic.status === 1 ? '启用' : '禁用' }}</el-descriptions-item>
        <el-descriptions-item label="权重">{{ currentTopic.weight }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>

    <!-- 查看选项对话框 -->
    <el-dialog
      title="题目选项"
      v-model="optionsDialogVisible"
      width="50%"
    >
      <el-table :data="currentOptions" style="width: 100%">
        <el-table-column prop="option" label="选项内容"></el-table-column>
        <el-table-column prop="option_type" label="选项类型">
          <template #default="scope">
            {{ getOptionTypeName(scope.row.option_type) }}
          </template>
        </el-table-column>
        <el-table-column prop="option_content" label="选项附加内容"></el-table-column>
      </el-table>
    </el-dialog>

        <!-- 查看所有分类对话框 -->
        <el-dialog
          title="所有分类"
          v-model="classifyDialogVisible"
          width="50%"
        >
          <el-tag
            v-for="classify in currentClassifies"
            :key="classify.id"
            class="mr-1 mb-1"
          >
            {{ classify.classify_name }}
          </el-tag>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch, nextTick } from 'vue'
import { Document, Folder, FolderOpened } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'
import env from '../../.env.js'; // 引入环境变量
import FileUpload from '@/components/FileUpload.vue'
import { getConstantByKey, NODE_TYPES } from '@/utils/constants'

export default {
  name: 'TopicManagement',
  components: {
    Document,
    Folder,
    FolderOpened,
    FileUpload // 添加 FileUpload 组件到 components 中
  },
  setup() {
    const apiBaseUrl = env.BASE_URL; // 获取基础URL

    // 如果需要添加认证Token等，可以在 headers 中添加
      // 从缓存中获取token
  const userInfoString = localStorage.getItem('userInfo');
  // 将JSON字符串转换为JSON对象
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  // 如果有token，则添加到header中
  const uploadHeaders = {}
  if (userInfo && userInfo.token) {
    uploadHeaders.Authorization = userInfo.token;
  }

    const searchForm = reactive({
      title: '',
      topic_type: '',
      classify_id: '',
      status: ''
    })
    const topics = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增题目')
    const topicForm = reactive({
      id: null,
      title: '',
      topic_type: '',
      content: '',
      options: [],
      answer: {},
      score_range: [0, 3000],
      pic: '',
      video: '',
      audio: '',
      file: '',
      status: 1,
      weight: 0,
      currentTag: '',
      tagList: [],
      classify_id: ''
    })

    const topicTypes = ref([])
    const optionTypes = ref([])
    const classifyTree = ref([])
    const classifyMap = ref({})
    const classifyMapReady = ref(false)
    const selectedClassifies = ref([])

    const picFileList = ref([])
    const videoFileList = ref([])
    const audioFileList = ref([])
    const fileFileList = ref([])

    const treeRef = ref(null)
    const currentPath = ref([])
    const defaultProps = {
      children: 'children',
      label: 'name',
      hasChildren: true
    }

    // 添加加载子节点的方法
    const loadNode = async (node, resolve) => {
      if (node.level === 0) {
        resolve(classifyTree.value)
        return
      }

      try {
        const parentId = node.data.id || node.data.base_info.id
        const res = await get(`/auth/admin/classify_nodes_by_parent/${parentId}`)
        if (res.data && res.data.nodes) {
          resolve(res.data.nodes)
        } else {
          resolve([])
        }
      } catch (error) {
        console.error('加载子节点失败:', error)
        ElMessage.error('加载子节点失败')
        resolve([])
      }
    }

    // 修改节点点击处理方法
    const handleNodeClick = async (data, node) => {
      // 清除之前的选中状态
      if (treeRef.value) {
        treeRef.value.setCurrentKey(data.id)
      }

      if (!node.expanded) {
        node.expanded = true
        if (!node.isLeaf) {
          try {
            const res = await get(`/auth/admin/classify_nodes_by_parent/${data.id}`)
            if (res.data && res.data.nodes) {
              node.childNodes = []
              if (node.data.children) {
                node.data.children.length = 0
              }
              
              res.data.nodes.forEach(childNode => {
                treeRef.value.append(childNode, node)
              })
            }
          } catch (error) {
            console.error('加载子节点失败:', error)
            ElMessage.error('加载子节点失败')
          }
        }
      } else {
        node.expanded = false
      }

      // 更新当前路径
      currentPath.value = []
      let currentNode = node
      while (currentNode.parent && currentNode.parent.level >= 0) {
        currentPath.value.unshift({
          id: currentNode.data.id,
          name: currentNode.data.name
        })
        currentNode = currentNode.parent
      }

      // 更新搜索条件并执行搜索
      searchForm.classify_id = data.id
      await searchTopics()

      // 如果是试卷节点，设置为默认选中
      if (data.node_type === NODE_TYPES.PAPER) {
        selectedClassify.value = data.id
      }
    }

    // 处理面包屑点击
    const handleBreadcrumbClick = async (item) => {
      try {
        // 在树中找到并定位到对应节点
        const node = treeRef.value?.getNode(item.id)
        if (node) {
          // 展开到当前节点的路径
          let currentNode = node
          while (currentNode.parent && currentNode.parent.level >= 0) {
            currentNode.parent.expanded = true
            currentNode = currentNode.parent
          }
          
          // 设置当前选中节点
          treeRef.value?.setCurrentKey(item.id)
          
          // 使用 nextTick 确保 DOM 已更新
          await nextTick()
          
          // 滚动到选中节点
          const nodeElement = document.querySelector(`.el-tree-node[data-key="${item.id}"]`)
          if (nodeElement) {
            nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
          
          // 触发节点点击事件
          handleNodeClick(node.data, node)
        }
      } catch (error) {
        console.error('面包屑导航错误:', error)
      }
    }

    const searchTopics = async () => {
      try {
        const params = {
          page: currentPage.value,
          size: pageSize.value,
          title: searchForm.title || '',
          topic_type: searchForm.topic_type || 0,
          classify_id: searchForm.classify_id || '',
          status: searchForm.status || 0
        }
        
        const res = await get('/auth/admin/topics', params)
        
        if (res.data && res.data.topics) {
          topics.value = res.data.topics
            .filter(topic => topic.base_info.id) // 过滤掉空数据
            .map(topic => ({
              id: topic.base_info.id,
              title: topic.title,
              topic_type: topic.topic_type,
              content: topic.content,
              classify_id: topic.classify_id,
              option_list: topic.option_list || [],
              answer: topic.answer,
              score_range: topic.score_range,
              pic: topic.pic,
              video: topic.video,
              audio: topic.audio,
              file: topic.file,
              status: topic.status,
              weight: topic.weight,
              created_at: topic.base_info.created_at,
              updated_at: topic.base_info.updated_at
            }))
          total.value = res.data.total
        }
      } catch (error) {
        console.error('获取题目列表失败:', error)
        ElMessage.error('获取题目列表失败')
        topics.value = []
        total.value = 0
      }
    }

    const resetSearch = () => {
      Object.keys(searchForm).forEach(key => {
        searchForm[key] = ''
      })
      currentPage.value = 1
      pageSize.value = 10
      currentPath.value = [] // 清空当前路径
      if (treeRef.value) {
        treeRef.value.setCurrentKey(null) // 清除树的选中状态
      }
      searchTopics()
    }

    const openCreateDialog = () => {
      dialogTitle.value = '新增题目'
      Object.assign(topicForm, {
        id: null,
        title: '',
        topic_type: '',
        content: '',
        classify_id_list: [],
        options: [],
        answer: {},
        score_range: [0, 3000],
        pic: '',
        video: '',
        audio: '',
        file: '',
        status: 1,
        weight: 0,
        currentTag: '',
        tagList: [],
        classify_id: selectedClassify.value || ''
      })
      
      selectedClassify.value = topicForm.classify_id
      
      picFileList.value = []
      videoFileList.value = []
      audioFileList.value = []
      fileFileList.value = []
      dialogVisible.value = true
    }

    const editTopic = async (row) => {
      if (!row) return
      dialogTitle.value = '编辑题目'
      
      // 确保 classifyMap 已经准备好
      if (!classifyMapReady.value) {
        await getClassifyTree()
      }

      // 设置表单数据
      Object.assign(topicForm, {
        id: row.id,
        title: row.title,
        topic_type: row.topic_type,
        content: row.content,
        classify_id: row.classify_id || '',
        options: row.option_list ? row.option_list.map(option => ({
          option: option.option,
          option_type: option.option_type,
          option_content: option.option_content || '',
          score: option.score || 0
        })) : [],
        answer: row.answer || {},
        score_range: row.score_range ? 
          row.score_range.split('-').map(Number) : 
          [0, 3000],
        pic: row.pic || '',
        video: row.video || '',
        audio: row.audio || '',
        file: row.file || '',
        status: row.status,
        weight: row.weight,
        currentTag: '',
        tagList: row.tags ? row.tags.split(',').filter(tag => tag) : [],
      })

      // 设置所属项目的选中状态
      selectedClassify.value = topicForm.classify_id

      // 如果有分类ID，确保在树形控件中显示正确的路径
      if (topicForm.classify_id && treeRef.value) {
        const node = treeRef.value.getNode(topicForm.classify_id)
        if (node) {
          // 展开到当前节点
          let currentNode = node
          while (currentNode.parent && currentNode.parent.level >= 0) {
            currentNode.parent.expanded = true
            currentNode = currentNode.parent
          }
          // 设置当前选中节点
          treeRef.value.setCurrentKey(topicForm.classify_id)

          // 更新面包屑导航
          currentPath.value = []
          let tempNode = node
          while (tempNode.parent && tempNode.parent.level >= 0) {
            currentPath.value.unshift({
              id: tempNode.data.id,
              name: tempNode.data.name
            })
            tempNode = tempNode.parent
          }
        }
      }

      // 设置文件列表
      picFileList.value = topicForm.pic ? [{ name: '当前图片', url: topicForm.pic }] : []
      videoFileList.value = topicForm.video ? [{ name: '当前视频', url: topicForm.video }] : []
      audioFileList.value = topicForm.audio ? [{ name: '当前音频', url: topicForm.audio }] : []
      fileFileList.value = topicForm.file ? [{ name: '当前文件', url: topicForm.file }] : []

      // 设置标签
      batchTags.value = topicForm.tagList.join(',')

      dialogVisible.value = true
    }

    const deleteTopic = (row) => {
      ElMessageBox.confirm('确定要删除这个题目吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/topic/${row.id}`)
          ElMessage.success('删除成功')
          searchTopics()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const getClassifyTree = async () => {
      try {
        const res = await get('/auth/admin/classify_tree')
        console.log('分类树响应数据:', res.data)
        if (res.data && res.data.nodes) {
          classifyTree.value = transformClassifyTree(res.data.nodes)
          updateClassifyMap(classifyTree.value)
          classifyMapReady.value = true
        }
      } catch (error) {
        console.error('获取项目树失败:', error)
        ElMessage.error('获取项目树失败')
      }
    }

    const transformClassifyTree = (nodes) => {
      if (!nodes) return [] // 添加空值检查
      return nodes.map(item => ({
        id: item.base_info.id,
        name: item.name,
        value: item.base_info.id,
        label: item.name,
        node_type: item.node_type,
        children: item.children ? transformClassifyTree(item.children) : [] // 确保返回空数组而不是 null
      }))
    }

    const updateClassifyMap = (tree) => {
      tree.forEach(node => {
        classifyMap.value[node.id] = node.name
        if (node.children && node.children.length > 0) {
          updateClassifyMap(node.children)
        }
      })
    }

    const getClassifyName = (classifyID) => {
      return classifyMap.value[classifyID] || '未知分类'
    }

    const handleTopicTypeChange = () => {
      topicForm.options = []
      topicForm.answer = {}
      topicForm.score_range = [0, 3000]
      
      // 如果是判断题,自动添加"是"和"否"选项
      if (topicForm.topic_type === getTopicTypeValue('判断题')) {
        topicForm.options = [
          {
            option: '是',
            option_type: getOptionTypeValue('正确'), // 假设选项类型中有"正确"类型
            option_content: '',
            score: 0
          },
          {
            option: '否',
            option_type: getOptionTypeValue('错误'), // 假设选项类型中有"错误"类型
            option_content: '',
            score: 0
          }
        ]
      }
    }

    const addOption = () => {
      topicForm.options.push({
        option: '',
        option_type: 1,
        option_content: '',
        score: 0
      })
    }

    const removeOption = (index) => {
      topicForm.options.splice(index, 1)
    }

    const handleClassifyChange = (value) => {
      if (!value) {
        topicForm.classify_id = ''
        return
      }

      // 在 classifyMap 中查找节点信息
      const node = findNodeById(classifyTree.value, value)
      if (!node) {
        // 如果在当前树中找不到，可能是懒加载的节点
        // 直接设置值，因为我们已经在懒加载时验证过节点类型
        topicForm.classify_id = value
        return
      }

      // 如果能找到节点，验证是否是试卷节点
      if (node.node_type !== NODE_TYPES.PAPER) {
        ElMessage.warning('请选择试卷类型的节点')
        selectedClassify.value = null
        return
      }

      topicForm.classify_id = value
    }

    const findNodeById = (nodes, id) => {
      for (const node of nodes) {
        if (node.id === id) {
          return node
        }
        if (node.children && node.children.length > 0) {
          const foundNode = findNodeById(node.children, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    }

    const removeClassify = (classifyId) => {
      const index = topicForm.classify_id_list.findIndex(item => item.classify_id === classifyId)
      if (index > -1) {
        topicForm.classify_id_list.splice(index, 1)
        // 同时更新 selectedClassifies
        const selectedIndex = selectedClassifies.value.indexOf(classifyId)
        if (selectedIndex > -1) {
          selectedClassifies.value.splice(selectedIndex, 1)
        }
      }
    }

    const handlePicUploadSuccess = (response) => {
      if (response && response.data && response.data.url) {
        topicForm.pic = response.data.url
        picFileList.value = [{ name: '当前图片', url: response.data.url }]
      } else {
        console.error('Invalid response from file upload:', response)
        ElMessage.error('图片上传失败，请重试')
      }
    }

    const handleVideoUploadSuccess = (response) => {
      topicForm.video = response.url
    }

    const handleAudioUploadSuccess = (response) => {
      topicForm.audio = response.url
    }

    const handleFileUploadSuccess = (response) => {
      topicForm.file = response.url
    }

    const beforeUpload = (file) => {
      console.log(file)
      // 在这里可以添加文件类型和大小的验证
      return true
    }

    const updateScoreRange = (value) => {
      if (Array.isArray(value) && value.length === 2) {
        topicForm.score_range = value // 确保 scoreRange 始终是一个数组
      }
    }

    const submitTopic = async () => {
      // 验证必填项
      if (!topicForm.classify_id) {
        ElMessage.error('请选择所属项目')
        return
      }

      // 构建基础提交数据
      const topicData = {
        title: topicForm.title,
        topic_type: topicForm.topic_type,
        content: topicForm.content,
        classify_id: topicForm.classify_id,
        options: topicForm.options.map(opt => ({
          option: opt.option,
          option_type: opt.option_type,
          option_content: opt.option_content || '',
          score: opt.score || 0
        })),
        answer: topicForm.answer,
        score_range: Array.isArray(topicForm.score_range) ? 
          `${topicForm.score_range[0]}-${topicForm.score_range[1]}` : 
          topicForm.score_range,
        pic: topicForm.pic || '',
        video: topicForm.video || '',
        audio: topicForm.audio || '',
        file: topicForm.file || '',
        status: topicForm.status,
        weight: topicForm.weight,
        tags: topicForm.tagList.join(',')
      }

      try {
        if (topicForm.id) {
          // 更新题目时，需要按照 UpdateTopicRequest 的结构包装数据
          const updateData = {
            topic: topicData
          }
          await put(`/auth/admin/topic/${topicForm.id}`, updateData)
          ElMessage.success('更新成功')
        } else {
          // 创建题目时直接使用 topicData
          await post('/auth/admin/topic', topicData)
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchTopics()
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      currentPage.value = 1 // 重置到第一页
      searchTopics()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchTopics()
    }

    const handleDialogClose = (done) => {
      ElMessageBox.confirm('确定要关闭吗？未保存的内容将会丢失。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        dialogVisible.value = false
        done()
      }).catch(() => {
        // 取消关闭
      })
    }

    const getTopicTypeName = (value) => {
      const typesData = getConstantByKey('topicTypes')
      if (!typesData || !typesData.types) return '未知类型'
      
      const types = typesData.types
      const type = types.find(t => t.value === value)
      return type ? type.key : '未知类型'
    }

    onMounted(async () => {
      // 获取目类型常量
      const topicTypesData = getConstantByKey('topicTypes')
      console.log('题目类型常量:', topicTypesData) // 添加日志
      
      if (topicTypesData && topicTypesData.types) {
        topicTypes.value = topicTypesData.types
      } else {
        console.error('题目类型数据格式错误')
        topicTypes.value = []
      }

      // 获取选项类型常量
      const optionTypesData = getConstantByKey('topicOptionTypes')
      console.log('选项类型常量:', optionTypesData) // 添加日志
      
      if (optionTypesData && optionTypesData.types) {
        optionTypes.value = optionTypesData.types
      } else {
        console.error('选项类型数据格式错误')
        optionTypes.value = []
      }

      await getClassifyTree() // 获取分类树
      await searchTopics()
    })

    // 监听 classifyTree 的变化
    watch(classifyTree, () => {
      updateClassifyMap(classifyTree.value)
      classifyMapReady.value = true
    })

    const classifyDialogVisible = ref(false)
    const optionsDialogVisible = ref(false)
    const moreInfoDialogVisible = ref(false)
    const currentClassifies = ref([])
    const currentOptions = ref([])
    const currentTopic = ref({})

    const showAllClassifies = (classifies) => {
      currentClassifies.value = classifies
      classifyDialogVisible.value = true
    }

    const showOptions = (options) => {
      currentOptions.value = options
      optionsDialogVisible.value = true
    }

    const showMoreInfo = (topic) => {
      currentTopic.value = topic
      moreInfoDialogVisible.value = true
    }

    const getOptionTypeName = (value) => {
      const type = optionTypes.value.find(t => t.value === value)
      return type ? type.key : '未知类型'
    }

    const formatDate = (timestamp) => {
      if (!timestamp) return '未知'
      const date = new Date(timestamp * 1000)
      return date.toLocaleString()
    }

    const getTopicTypeValue = (typeName) => {
      const typesData = getConstantByKey('topicTypes')
      if (!typesData || !typesData.types) return null

      const types = typesData.types
      const type = types.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    const getOptionTypeValue = (typeName) => {
      const typesData = getConstantByKey('topicOptionTypes')
      if (!typesData || !typesData.types) return null
        
      const types = typesData.types
      const type = types.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    const fileTypes = ref(getConstantByKey('fileTypes') || [])

    const getFileTypeValue = (typeName) => {
      const type = fileTypes.value.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    // 在 setup() 中添加新的响应式数据
    const activeCollapse = ref([])
    const selectedClassify = ref(null)
    const batchTags = ref('')

    // 标签相关方法
    const addTag = () => {
      if (!topicForm.currentTag.trim()) return
      
      if (!topicForm.tagList.includes(topicForm.currentTag.trim())) {
        topicForm.tagList.push(topicForm.currentTag.trim())
        batchTags.value = topicForm.tagList.join(',')
      }
      topicForm.currentTag = ''
    }

    const removeTag = (tag) => {
      topicForm.tagList = topicForm.tagList.filter(t => t !== tag)
      batchTags.value = topicForm.tagList.join(',')
    }

    const handleBatchTags = (value) => {
      if (!value) {
        topicForm.tagList = []
        return
      }
      const tags = value.split(',')
        .map(tag => tag.trim())
        .filter(tag => tag)
      
      topicForm.tagList = [...new Set(tags)]
      batchTags.value = topicForm.tagList.join(',')
    }

    const handleCascaderLazyLoad = async (node, resolve) => {
      // 根节点
      if (node.level === 0) {
        resolve(classifyTree.value)
        return
      }

      try {
        const parentId = node.data.id
        const res = await get(`/auth/admin/classify_nodes_by_parent/${parentId}`)
        if (res.data && res.data.nodes) {
          // 转换数据格式以适应 cascader
          const nodes = res.data.nodes.map(item => ({
            id: item.base_info.id,
            name: item.name,
            value: item.base_info.id,
            label: item.name,
            node_type: item.node_type,
            leaf: item.node_type === NODE_TYPES.PAPER // 如果是试卷节点则为叶子节点
          }))
          resolve(nodes)
        } else {
          resolve([])
        }
      } catch (error) {
        console.error('加载子节点失败:', error)
        ElMessage.error('加载子节点失败')
        resolve([])
      }
    }

    return {
      apiBaseUrl, // 导出基础URL
      uploadHeaders, // 导出上传头部
      searchForm,
      topics,
      currentPage,
      pageSize,
      total,
      dialogVisible,
      dialogTitle,
      topicForm,
      topicTypes,
      optionTypes,
      classifyTree,
      selectedClassifies,
      searchTopics,
      resetSearch,
      openCreateDialog,
      editTopic,
      deleteTopic,
      handleTopicTypeChange,
      addOption,
      removeOption,
      handleClassifyChange,
      removeClassify,
      getClassifyName,
      handlePicUploadSuccess,
      handleVideoUploadSuccess,
      handleAudioUploadSuccess,
      handleFileUploadSuccess,
      beforeUpload,
      updateScoreRange,
      submitTopic,
      handleSizeChange,
      handleCurrentChange,
      handleDialogClose,
      getTopicTypeName,
      classifyDialogVisible,
      optionsDialogVisible,
      moreInfoDialogVisible,
      currentClassifies,
      currentOptions,
      currentTopic,
      showAllClassifies,
      showOptions,
      showMoreInfo,
      getOptionTypeName,
      formatDate,
      getTopicTypeValue,
      getOptionTypeValue,
      picFileList,
      videoFileList,
      audioFileList,
      fileFileList,
      fileTypes,
      getFileTypeValue,
      treeRef,
      currentPath,
      defaultProps,
      handleNodeClick,
      handleBreadcrumbClick,
      activeCollapse,
      selectedClassify,
      batchTags,
      addTag,
      removeTag,
      handleBatchTags,
      NODE_TYPES,
      loadNode,
      handleCascaderLazyLoad
    }
  }
}
</script>

<style scoped>
.topic-management {
  height: 100vh;
  background-color: var(--el-bg-color);
}

.tree-aside {
  border-right: 1px solid var(--el-border-color-light);
  background-color: var(--el-bg-color);
  transition: width 0.3s;
  padding: 20px;
}

.tree-card {
  height: calc(100vh - 40px);
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.custom-tree {
  padding: 16px;
}

.custom-tree :deep(.el-tree-node) {
  margin: 4px 0;
}

.custom-tree :deep(.el-tree-node__content) {
  height: 40px;
  border-radius: 4px;
  transition: all 0.3s;
}

.custom-tree :deep(.el-tree-node__content:hover) {
  background-color: var(--el-color-primary-light-9);
}

.custom-tree :deep(.el-tree-node.is-current > .el-tree-node__content) {
  background-color: var(--el-color-primary-light-8);
  color: var(--el-color-primary);
}

.custom-tree-node {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  width: 100%;
}

.node-text {
  font-size: 14px;
  flex: 1;
}

.node-text.is-paper {
  color: var(--el-color-primary);
  font-weight: 500;
}

/* 面包屑样式 */
.breadcrumb-item {
  cursor: pointer;
  transition: color 0.3s;
}

.breadcrumb-item:hover {
  color: var(--el-color-primary);
}

:deep(.el-breadcrumb__item) {
  cursor: pointer;
}

:deep(.el-breadcrumb__inner) {
  &:hover {
    color: var(--el-color-primary);
  }
}

/* 图标样式 */
.el-icon {
  font-size: 16px;
  color: var(--el-text-color-secondary);
}

/* 滚动条样式 */
:deep(.el-scrollbar__wrap) {
  overflow-x: hidden;
}

:deep(.el-tree-node__expand-icon) {
  font-size: 16px;
}

/* 选中节点的高亮效果 */
:deep(.el-tree-node.is-current > .el-tree-node__content) {
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--el-color-primary);
    border-radius: 0 2px 2px 0;
  }
}

.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.topic-list-card {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.pagination-container {
  margin-top: auto;
  padding: 1rem 0;
}
.el-divider {
  background-color: #f0f2f5;
  margin: 24px 0;
  border-radius: 4px;
}
.el-divider__text {
  background-color: #fff;
  padding: 0 20px;
  font-weight: bold;
  color: #606266;
}
:deep(.el-dialog__body) {
  padding: 0;
}

:deep(.el-scrollbar__view) {
  padding: 20px;
}

.option-row {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.option-row .el-input {
  flex-grow: 1;
}

.option-row .el-select {
  width: 120px;
}

.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}

.project-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selected-tags {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.el-cascader {
  width: 100%;
}

.project-selection {
  margin-bottom: 10px;
}

.selected-classify {
  margin-top: 8px;
  color: #666;
}

.tag-input-area {
  margin-bottom: 15px;
}

.single-tag-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.tag-display {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.option-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.project-selection {
  margin-bottom: 10px;
}

.selected-classify {
  margin-top: 8px;
  color: #666;
}

.tag-input-area {
  margin-bottom: 15px;
}

.single-tag-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.tag-display {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.option-row {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>